import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import DatePicker from '../utils/DatePicker';
import UploadImage from '../utils/UploadImage';
import { database } from '../../base';
import '../../styles/css/AdminMain.css';
import RichTextEditor from 'html-verify-react-rte';
import { PhotoshopPicker } from "react-color";
import { Toast, ErrorMessage, WarningMessage } from '../utils/HelpfulFunction';
import BasicDropzone from '../utils/Dropzone';
import { ref, onValue, set, remove, push } from 'firebase/database';

class SetUpRewards extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            ticketList: [],
            editingTicket: false,
            rewardToEdit: null,
            imageUrl: null,
            modal: false,
            emailImage: null,
            rewardName: "",
            rewardDisplayName: "",
            rewardsList: [],
            rewardLink: "",
            rewardLinkButtonText: "",
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true,
            rewardDescription: RichTextEditor.createEmptyValue(),
            editTicketId: "",
            editTicketEmailImage: '',
            emailImagePreview: {},
            loading: true,
            files: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const rewardsListRef = ref(database, 'rewardsList');
        onValue(rewardsListRef, (snapshot) => {
            const data = snapshot.val() || {};
            const rewardsList = Object.keys(data).map(key => ({ ...data[key], key }));
            this.setState({ rewardsList, loading: false });
        });
    }

    onDrop(files, rejected, myArgument) {
        if (rejected.length > 0) {
            ErrorMessage.fire({
                title: 'Image cannot be uploaded',
                text: 'Make sure the image is less than 2mbs and it is an accepted file type'
            });
            return;
        }
        this.setState({ loading: true });

        this.UploadImage.upload_file(files[0]).then(res => {
            this.setState({ loading: false });
            if (res.error) {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: res.error,
                });
            } else if (res.imageUrl) {
                const nameToUpdate = myArgument + "Preview";
                const fileToUpdate = files[0];
                this.setState({
                    [myArgument]: res.imageUrl,
                    [nameToUpdate]: ({
                        fileToUpdate,
                        preview: URL.createObjectURL(fileToUpdate)
                    })
                });
            } else {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: 'Something went wrong, please re-upload your image and try again!'
                });
            }
        });
    }

    onChangeDescription = (rewardDescription) => {
        this.setState({ rewardDescription });
    }

    openColorPicker(colorToChange) {
        this.setState({
            colorPickerShow: true,
            colorToShow: this.state[colorToChange],
            colorToUpdate: colorToChange
        });
    }

    handleChangeComplete = (color) => {
        this.setState({ colorToShow: color.hex });
    };

    acceptPicker() {
        const colorToChange = this.state.colorToUpdate;
        this.setState({
            [colorToChange]: this.state.colorToShow,
            updateHappend: true,
            colorPickerShow: false,
        });
    }

    dismissPicker() {
        this.setState({ colorPickerShow: false });
    }

    componentWillUnmount() {
        // Make sure to revoke the data uris to avoid memory leaks
        URL.revokeObjectURL(this.state.emailImagePreview.preview);
    }

    handleChange(evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [target.name]: value });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.loading) return;

        const rewardName = this.state.rewardName || "";
        const rewardDisplayName = this.state.rewardDisplayName || "";
        let rewardLink = this.state.rewardLink || "";
        let rewardLinkButtonText = this.state.rewardLinkButtonText || "";
        let rewardLinkButtonTextColor = this.state.rewardLinkButtonTextColor || "";
        let rewardLinkButtonColor = this.state.rewardLinkButtonColor || "";
        let vendorName = this.state.vendorName || "";
        let templateText = this.state.templateText || "";
        let expiresAtHours = this.state.expiresAtHours || "";
        let expiresAtDateTime = this.state.expiresAtDateTime || "";
        let isRedeemable = this.state.isRedeemable || false;
        let showButton = this.state.showButton || false;
        if (isRedeemable) {
            rewardLink = "";
            showButton = true;
        }
        let rewardDescription = this.state.rewardDescription.toString('html');
        if (rewardDescription === "<p><br></p>") {
            rewardDescription = "";
        }
        const emailImage = this.state.emailImage || this.state.editTicketEmailImage || "";
        const rewardEmailName = this.state.rewardEmailName || "";
        const vm = this;
        if (!rewardName) {
            ErrorMessage.fire({
                title: 'Missing Info',
                text: 'Please enter a name'
            });
            return;
        }
        if (vendorName && vendorName.length > 50) {
            ErrorMessage.fire({
                title: 'Oh Uh!',
                text: 'The vendor name is too long, make sure it is 50 characters or less'
            });
            return;
        }
        if (templateText && templateText.length > 300) {
            ErrorMessage.fire({
                title: 'Oh Uh!',
                text: 'The template text is too long, make sure it is 300 characters or less',
            });
            return;
        }
        if (!showButton) {
            rewardLinkButtonText = "";
            rewardLinkButtonTextColor = "";
            rewardLinkButtonColor = "";
        }
        const rewardToSave = {
            rewardName,
            rewardDisplayName,
            emailImage,
            rewardLink,
            rewardLinkButtonText,
            rewardLinkButtonTextColor,
            rewardLinkButtonColor,
            showButton,
            vendorName,
            expiresAtDateTime,
            templateText,
            expiresAtHours,
            description: rewardDescription,
            rewardEmailName,
            isRedeemable,
            id: (vm.state.rewardToEdit && vm.state.rewardToEdit.key) || push(ref(database, 'rewardsList')).key
        };

        this.setState({ loading: true });

        set(ref(database, `rewardsList/${rewardToSave.id}`), rewardToSave)
            .then(() => {
                Toast.fire({
                    title: vm.state.rewardToEdit ? 'Successfully Edited!' : 'Successfully Created!'
                });
                vm.setState({
                    rewardDescription: RichTextEditor.createEmptyValue(),
                    fileEmailImage: null,
                    rewardName: "",
                    rewardDisplayName: "",
                    emailImage: null,
                    rewardLink: "",
                    rewardLinkButtonText: "",
                    rewardLinkButtonTextColor: "",
                    rewardLinkButtonColor: "",
                    rewardEmailName: "",
                    rewardToEdit: null,
                    emailImagePreview: {},
                    editingTicket: false,
                    showButton: false,
                    modal: false,
                    loading: false,
                    isRedeemable: false,
                    expiresAtHours: "",
                    expiresAtDateTime: "",
                    templateText: "",
                    vendorName: "",
                });
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
                vm.setState({ loading: false });
                ErrorMessage.fire({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in'
                });
            });
    }

    editTicket(event) {
        event.preventDefault();
        const array = this.state.rewardsList;
        const reward = array[event.target.value];
        this.setState({
            modal: true,
            rewardName: reward.rewardName,
            rewardDisplayName: reward.rewardDisplayName,
            rewardLink: reward.rewardLink,
            rewardDescription: RichTextEditor.createValueFromString(reward.description, 'html'),
            rewardLinkButtonText: reward.rewardLinkButtonText,
            rewardLinkButtonColor: reward.rewardLinkButtonColor,
            rewardLinkButtonTextColor: reward.rewardLinkButtonTextColor,
            editTicketEmailImage: reward.emailImage,
            rewardEmailName: reward.rewardEmailName,
            showButton: reward.showButton,
            rewardToEdit: reward,
            editingTicket: true,
            isRedeemable: reward.isRedeemable,
            expiresAtHours: reward.expiresAtHours,
            expiresAtDateTime: reward.expiresAtDateTime,
            vendorName: reward.vendorName,
            templateText: reward.templateText
        });
    }

    async deleteTicket(e) {
        e.preventDefault();
        const array = this.state.rewardsList;
        const index = array[e.target.value];
        const response = await WarningMessage.fire({
            title: 'Delete Prize?',
            text: 'Are you sure you want to do this?  You will no longer be able to use this prize in any new games',
            confirmButtonText: 'Delete'
        });
        if (response.value) {
            remove(ref(database, 'rewardsList/' + index.key)).catch(err => {
                ErrorMessage.fire({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                });
            });
        }
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            rewardName: "",
            rewardDisplayName: "",
            rewardLink: "",
            rewardDescription: RichTextEditor.createEmptyValue(),
            rewardLinkButtonText: "",
            rewardLinkButtonColor: "",
            rewardLinkButtonTextColor: "",
            rewardEmailName: "",
            editTicketEmailImage: null,
            expiresAtHours: "",
            expiresAtDateTime: "",
            rewardToEdit: null,
            showButton: false,
            editingTicket: false,
            isRedeemable: false,
            templateText: "",
            vendorName: "",
        });
    }

    render() {
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        const winningTicketList = this.state.rewardsList;
        const emailImagePreview = this.state.emailImagePreview;
        const toolbarConfig = {
            display: ['INLINE_STYLE_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        if (!isMlbApp) {
            toolbarConfig.display.push('LINK_BUTTONS');
        }

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="card">
                        <div className="card-body">
                            <p className="admin-header-text" style={{ marginBottom: 0 }}>Prizes</p>
                            <p className="admin-subheader-text">These are prizes fans will receive when they win</p>
                            <button className="btn btn-primary btn-lg create-prize-button" style={{ fontSize: 20, marginLeft: 20 }} onClick={this.toggle}>Add Prize</button>
                            <div className="admin-grid-container four-columns" style={{ marginTop: 20 }}>
                                {
                                    winningTicketList.map(function (item, i) {
                                        return <div key={i} className="card">
                                            <div className="card-body" align="center">
                                                <p style={{ marginTop: 5 }}>{item.rewardName}</p>
                                                <p>
                                                    <img width="80%" height="auto" src={item.emailImage} alt="" />
                                                </p>
                                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight: 5, marginBottom: 10 }} onClick={this.editTicket.bind(this)} value={i}><span className="fa fa-ellipsis-v" /> Edit</button>
                                                <button className="btn btn-primary btn-lg delete-button" style={{ marginBottom: 10 }} onClick={this.deleteTicket.bind(this)} value={i}><span className="fa fa-trash-o" /> Delete</button>
                                            </div>
                                        </div>
                                    }, this)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} style={{ width: '90%' }} id="myModal">
                    <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                    <ModalHeader toggle={this.toggle}>Add Prize</ModalHeader>
                    <ModalBody>
                        <div className="container-out">
                            <div className="question-box question-form">
                                <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                                    <div className="form-group">
                                        <label htmlFor="rewardName">Prize Name (Only On The Admin)</label>
                                        <input id="rewardName" name="rewardName" type="text" className="form-control" value={this.state.rewardName} onChange={this.handleChange} placeholder="My Internal Sweet Prize Name" />
                                    </div>
                                    <div className="form-group" >
                                        <label htmlFor="rewardDisplayName">Prize Display Name (Optional)</label>
                                        <input id="rewardDisplayName" name="rewardDisplayName" type="text" className="form-control" value={this.state.rewardDisplayName} onChange={this.handleChange} placeholder="My Sweet Prize" />
                                    </div>
                                    {!isMlbApp &&
                                        <div className="form-group">
                                            <label htmlFor="rewardLink">Prize Link (Optional)</label>
                                            <span> OR </span>
                                            <label htmlFor="showRewardsOutMessage">Sqwad Redeem Process</label>
                                            <input type="checkbox" checked={this.state.isRedeemable} id="isRedeemable" name="isRedeemable" onChange={this.handleChange} />
                                            {!this.state.isRedeemable &&
                                                <input id="rewardLink" name="rewardLink" type="url" className="form-control" value={this.state.rewardLink} onChange={this.handleChange} placeholder="http://my_sweet_prize_link.com" />
                                            }
                                        </div>
                                    }
                                    {this.state.rewardLink && !isMlbApp &&
                                        <div className="form-group">
                                            <label htmlFor="showButton">Show Button</label>
                                            <br />
                                            <input type="checkbox" checked={this.state.isRedeemable || this.state.showButton} id="showButton" name="showButton" onChange={this.handleChange} disabled={this.state.isRedeemable && true} />
                                        </div>
                                    }
                                    {(((this.state.rewardLink && this.state.showButton) || this.state.isRedeemable) || isMlbApp) &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="rewardLinkButtonText">Prize Link Button Text (Optional)</label>
                                                <input id="rewardLinkButtonText" name="rewardLinkButtonText" type="text" className="form-control" value={this.state.rewardLinkButtonText} onChange={this.handleChange} placeholder={this.state.isRedeemable ? "Store Redeem" : "GET MY PRIZE!"} />
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-inline">
                                                        <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("rewardLinkButtonTextColor")} />
                                                        <div style={{ backgroundColor: this.state.rewardLinkButtonTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                        <label htmlFor="rewardLinkButtonTextColor" style={{ marginRight: 10 }}>Button Text Color</label>
                                                        <input id="rewardLinkButtonTextColor" name="rewardLinkButtonTextColor" type="text" className="form-control" value={this.state.rewardLinkButtonTextColor} onChange={this.handleChange} placeholder="#000" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-inline">
                                                        <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("rewardLinkButtonColor")} />
                                                        <div style={{ backgroundColor: this.state.rewardLinkButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                        <label htmlFor="rewardLinkButtonColor" style={{ marginRight: 10 }}>Button Color</label>
                                                        <input id="rewardLinkButtonColor" name="rewardLinkButtonColor" type="text" className="form-control" value={this.state.rewardLinkButtonColor} onChange={this.handleChange} placeholder="#000" />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    { !isMlbApp &&
                                        <div className="form-group">
                                            <label htmlFor="rewardDescription">Prize Description (Optional) </label>
                                            <RichTextEditor rootStyle={{ fontFamily: 'Roboto' }} toolbarConfig={toolbarConfig} id="rewardDescription" name="rewardDescription" type="text" value={this.state.rewardDescription} onChange={this.onChangeDescription} placeholder="My Sweet Prize Description" />
                                        </div>
                                    }
                                    {isMlbApp &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="vendorName">Vendor Name</label>
                                                <input id="vendorName" name="vendorName" type="text" className="form-control" value={this.state.vendorName} onChange={this.handleChange} placeholder="Your sponsors name" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="templateText">Template Text</label>
                                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This text should finish the phrase: You have an update from </p>
                                                <input id="templateText" name="templateText" className="form-control" value={this.state.templateText} onChange={this.handleChange} />
                                            </div>
                                        </>
                                    }
                                    {isMlbApp &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="advanced">Advanced</label>
                                                <br />
                                                <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange} />
                                            </div>
                                            {this.state.advanced && isMlbApp &&
                                                <>
                                                    <div className="form-group">
                                                        <label htmlFor="expiresAtHours">Hours Until Expire</label>
                                                        <input id="expiresAtHours" name="expiresAtHours" step="1" type="number" className="form-control" value={this.state.expiresAtHours} onChange={this.handleChange} placeholder="720" />
                                                    </div>
                                                    OR/AND
                                                    <div className="form-group row mb-3 form_times">
                                                        <label className="col-md-3 col-form-label" htmlFor="expiresAtDateTime"> Date Until Expire</label>
                                                        <div className="col-md-9">
                                                            <DatePicker showTimeInput dateFormat="Pp" selected={this.state.expiresAtDateTime} onChange={date => this.setState({ expiresAtDateTime: date })} />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                    <div className="form-group" align="center">
                                        <label htmlFor="rewardEmailImage" style={{ width: '100%' }}>
                                            Prize Email Image
                                            <span style={{ cursor: "pointer", display: emailImagePreview.preview || this.state.editTicketEmailImage ? "" : "none" }} onClick={() => this.setState({ editTicketEmailImage: null, fileUploaded: false, emailImagePreview: {}, emailImage: null })}>
                                                ❌
                                            </span>
                                        </label>
                                        <img src={this.state.editTicketEmailImage} width="auto" height="100px" style={{ display: this.state.editingTicket ? '' : 'none' }} alt="" />
                                        <BasicDropzone
                                            onDrop={(acceptedFiles, fileRejections) => this.onDrop(acceptedFiles, fileRejections, "emailImage")}
                                            preview={emailImagePreview.preview}
                                        />
                                    </div>
                                    <div className="form-group text-center">
                                        <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
                    <PhotoshopPicker onChangeComplete={this.handleChangeComplete} color={this.state.colorToShow} onAccept={() => this.acceptPicker()} onCancel={() => this.dismissPicker()} />
                </Modal>
            </div>
        );
    }
}

export default SetUpRewards;
